import React from 'react';

const InputHidden = ({ name, value, register }) => {
  return (
    <input
      type="hidden"
      name={name}
      value={value}
      {...register(name)}
    />
  );
};

export default InputHidden;
